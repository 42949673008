@import url(https://fonts.googleapis.com/css?family=Noto+Sans+TC:regular,bold,italic);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'MyFont2';
  font-style: normal;
  font-weight: normal;
  src: local('MyFont2'), url(/static/media/DFLIHL1B.f2833ad6.TTF) format('truetype');
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.FileInput {
  width: 100%;
  height: 50px;
}

.Lyrics {
  white-space: pre-wrap;
  font-size: 80px;
  height: 280px;
  background-color: black;
  text-shadow:  2px   0  0   #000, 
  -2px   0  0   #000, 
   0    2px 0   #000, 
   0   -2px 0   #000, 
   1px  1px 0   #000, 
  -1px -1px 0   #000, 
   1px -1px 0   #000, 
  -1px  1px 0   #000,
   1px  1px 5px #000;
}

.LyricsSpan {
  display: inline-block;
  line-height: 92px; /* <-- adjust this */
  vertical-align: middle;
  margin-top: 40px;
}

.Title {
  white-space: pre-wrap;
  font-size: 50px;
  height: 280px;
  line-height: 200px;
  background-color: black;
  text-shadow:  2px   0  0   #000, 
  -2px   0  0   #000, 
   0    2px 0   #000, 
   0   -2px 0   #000, 
   1px  1px 0   #000, 
  -1px -1px 0   #000, 
   1px -1px 0   #000, 
  -1px  1px 0   #000,
   1px  1px 5px #000;
}

.TitleSpan {
  display: inline-block;
  vertical-align: middle;
  line-height: 58px; /* <-- adjust this */
  margin-top: 40px;
}

.TitleGreen {
  white-space: pre-wrap;
  font-size: 50px;
  height: 200px;
  line-height: 200px;
  background-color: black;
}

.InfoHeader {
  white-space: pre-wrap;
  font-size: 18px;
  width: 100%;
  height: 100px;
  background-color: beige;
  color: black;
  vertical-align: middle;
}

.Content {
  flex: 1 1;
}

.Footer {
  width: 100%;
  margin-bottom: 50px;
  font-family: 'Noto Sans TC';
}

.Copyright {
  width: 100%;
  font-size: 15px;
  margin-bottom: 5px;
  margin-right: 20px;
  text-align: right;
}
