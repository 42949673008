body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'MyFont2';
  font-style: normal;
  font-weight: normal;
  src: local('MyFont2'), url(./fonts/DFLIHL1B.TTF) format('truetype');
}

@import url('https://fonts.googleapis.com/css?family=Noto+Sans+TC:regular,bold,italic');